import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"

const BilderPage = () => {
  return (
    <>
      <Layout pageTitle="Bildene fra byllupet!">
        <SEO title="Brøllup" />
        <p>
          Send oss en melding på SMS eller Facebook, så skal vi gi deg
          passordet.
        </p>
        <p className="mb-2">Bildene finner du ved å følge lenken under.</p>
        <a
          className="text-blue-600 underline font-normal"
          title="Bildene"
          href="/bildene"
        >
          Bildene
        </a>
      </Layout>
    </>
  )
}

export default BilderPage
